import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Title = makeShortcode("Title");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <InlineNotification kind="error" mdxType="InlineNotification">
As the Instagram API has been completely overhauled, I'm waiting for a new auth key from Facebook to get this page working again.
      </InlineNotification>
    </PageDescription>
    <Row mdxType="Row">
      <Column colLg='8' mdxType="Column">
        <Title mdxType="Title">Will Farrell is Sad too</Title>
        <GifPlayer mdxType="GifPlayer">
          <img {...{
            "src": "https://media.giphy.com/media/1BXa2alBjrCXC/giphy.gif",
            "alt": "Will Farrell Sad"
          }}></img>
        </GifPlayer>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      